
/*==========  BUTTONS  ==========*/

.btn {
	display: inline-block;
	vertical-align: bottom;
	text-align: center;
	position: relative;
	cursor: pointer;
	text-decoration: none;
	border: none;
	padding: 0;
	background: none;
	outline: none;
	font-size: 14px;
	line-height: 1.4em;
	font-weight: 500;
	&-wrapper{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		.btn{
			margin-right: 8px;
			&:last-child{
				margin-right: 0;
			}
		}
		&.right{
			justify-content: flex-end;
		}
		&.center{
			justify-content: center;
		}
	}
}

.btn-primary {
	padding: 6px 12px;
	border-radius: 6px;
	background-color: $color1;
	color: #fff;
	transition: $transition;
	&.is-active{
		background-color: #7161F3;
	}
	&.disable{
		background-color: #D0D5DD;
		color: #667085;
		pointer-events: none;
	}
	&.loader{
		color: rgba(1,1,1,0);
		pointer-events: none;
		position: relative;
		&:after{
			content: '';
			display: block;
			width: 16px;
			height: 16px;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -8px;
			margin-top: -8px;
			background-image: url('../../assets/img/loader-image-2-white.svg');
			background-size: 100%;
			animation: spin 1s linear infinite;
		}
	}
	&.btn-loader{
		transition: all 0.14s ease-out;
	}
}

.btn-secondary {
	padding: 6px 12px;
	border-radius: 6px;
	background-color: $colorGrey1;
	color: $color3;
	transition: $transition;
	&.is-active{
		background-color: $color4;
		color: $color1;
	}
	&.disable{
		background-color: $colorGrey2;
		color: $colorGrey3;
		pointer-events: none;
	}
}

.btn-outlined {
	padding: 6px 12px;
	border-radius: 6px;
	background-color: rgba(1,1,1,0);
	border: 1px solid $colorGrey5;
	color: #667085;
	transition: $transition;
	&.is-active{
		background-color: $color5;
	}
	&.disable{
		border-color: $colorGrey2;
		color: $colorGrey3;
		pointer-events: none;
	}
	&.loader{
		color: rgba(1,1,1,0);
		pointer-events: none;
		position: relative;
		&:after{
			content: '';
			display: block;
			width: 16px;
			height: 16px;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -8px;
			margin-top: -8px;
			background-image: url('../../assets/img/loader-image-2.svg');
			background-size: 100%;
			animation: spin 1s linear infinite;
		}
	}
	&.btn-loader{
		transition: all 0.14s ease-out;
	}
}

.btn-ghost{
	padding: 6px 12px;
	border-radius: 6px;
	background-color: rgba(1,1,1,0);
	color: $color1;
	transition: $transition;
	&.is-active{
		background-color: $color4;
	}
	&.disable{
		color: $colorGrey3;
		pointer-events: none;
	}
}

.btn-block {
	display: block;
	width: 100%;
}

.upload{
	&-die{
		border-radius: 8px;
		background-color: #E9E9E9;
		border: 1px solid #E6E6E6;
		padding: 8px 40px;
		position: relative;
		display: flex;
		align-items: center;
		display: inline-block;
		&.w-100{
			width: 100%;
		}
		.size{
			background-color: $colorGrey6-2;
			color: #6F6F6F;
			padding: 2px 8px;
			border-radius: 16px;
			font-size: 14px;
			display: inline-block;
		}
		.file{
			color: #161616;
			font-size: 14px;
			margin-right: 10px;
		}
		.icon{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 16px;
			line-height: 0;
			display: inline-block;
			width: 16px;
			img{
				width: 100%;
			}
		}
		.clean{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 16px;
			line-height: 0;
			display: inline-block;
			width: 13px;
			img{
				width: 100%;
			}
		}
	}
	&-wrapper{
		.upload-die{
			margin-top: 8px;
		}
	}
}



/*==========  Hover  ==========*/

@media (min-width: 991px){
	.btn-primary:hover:not(.is-active){
		background-color: #7161F3;
	}
	.btn-secondary:hover:not(.is-active){
		background-color: $colorGrey4;
	}
	.btn-outlined:hover:not(.is-active){
		background-color: $color5;
	}
	.btn-ghost:hover:not(.is-active){
		background-color: $color5;
	}
}

/*==========  MEDIA QUERIES  ==========*/

// @media (max-width: 1368px)

// @media (max-width: 1199px)

// @media (max-width: 991px)

// @media (max-width: 768px)

@media (max-width: 575px){
	.btn-block-sm{
		display: block;
		width: 100%;
	}
	.btn-fixed-sm{
		position: fixed;
		left: 16px;
		bottom: 16px;
		right: 16px;
		width: calc(100% - 32px);
		text-align: center;
		box-sizing: border-box;
	}
}
