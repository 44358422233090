/*==========  Header Style  ==========*/

.lab-success{
  &-page{
    padding-top: 40px;
  }
  &-logo{
    display: block;
    width: 195px;
    margin: 0 auto 15px auto;
    img{
      max-width: 100%;
    }
  }
  &-image{
    position: relative;
    z-index: 0;
    img{
      max-width: calc(100vh - 280px);
    }
  }
}
.decor-bg{
  position: absolute;
  background-color: #FFFFFF;
  display: block;
  width: 100%;
  height: 80%;
  left: 0;
  right: 0;
  border-radius: 16px;
  z-index: -1;
  &.pos-bottom{
    bottom: 0;
  }
}

/*==========  Hover  ==========*/

// @media (min-width: 1200px){}

/*==========  MEDIA QUERIES  ==========*/

@media (max-width: 1500px) {
  .lab-success{
    &-page{
      padding-top: 0;
    }
  }
}

@media (max-width: 1199px){
  .lab-success-logo{
    max-width: 180px;
  }
}

@media (max-width: 991px){
  .lab-success{
    &-page{
      padding-top: 0;
    }
    &-logo{
      width: 145px;
    }
  }
}

// @media (max-width: 768px){}

// @media (max-width: 575px){}




