
































































































































































































































































































































































































































































.checkbox-entry{
  &.type2{
    margin-bottom: 0!important;
    font-weight: 400;
  }
}
