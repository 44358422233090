$mainFont: 'Inter', sans-serif;

$transition: all .34s ease-out;

$color1: #5E4BF1;
$color2: #02BB9F;
$color3: #161616;
$color4: rgba(94, 75, 241, 0.12);
$color5:  rgba(94, 75, 241, 0.08);

$colorGrey1: #E9E9E9;
$colorGrey2: #F0F0F0;
$colorGrey3: #AAAAAA;
$colorGrey4: #E2E2E2;
$colorGrey5: #E6E6E6;
$colorGrey6: rgba(0, 0, 0, 0.04);
$colorGrey6-2: rgba(0, 0, 0, 0.08);
$colorGrey7: #767676;
