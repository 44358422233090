




































































































































































































.upload-die{
  .loader-wrapper{
    display: none;
  }
  &.loader{
    .loader-wrapper{
      display: block;
      position: absolute;
      width: 20px;
      padding: 0;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
      line-height: 0;
    }
    .loader-circle{
      width: 16px;
    }
    .icon{
      display: none;
    }
  }
}
.btn-wrapper-or{
  display: flex;
  align-items: center;
  > * {
    margin-right: 8px;
    &:last-child{
      margin-right: 0;
    }
  }
}
