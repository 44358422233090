









































































































































































































































































































































































































































































































.profile-ed-mn{
  padding: 24px;
  border-radius: 16px;
  background-color: #fff;
  &-image{
    width: 56px;
    height: 56px;
    border-radius: 50%;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}
.vc-container{
  background-color: #f7f7fa;
  border-radius: 6px;
  border: none;
  .vc-title{
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    text-transform: capitalize;
    transition: all .34s ease-out;
  }
  .vc-day-content{
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 6px;
    color: #000000;
    font-size: 14px;
    transition: all .34s ease-out;
    font-weight: 400!important;
    &.is-disabled{
      pointer-events: none;
    }
  }
  .vc-highlight{
    width: 32px;
    height: 32px;
    transition: all .34s ease-out;
    background-color: #5E4BF1!important;
    border-radius: 6px!important;
  }
  .vc-day{
    margin-top: 1px;
    margin-bottom: 1px;
    &.is-not-in-month *{
      opacity: 1;
      color: #AAAAAA;
    }
  }
  .vc-weekday{
    color: #AAAAAA;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
  }
  .vc-arrows-container{
    padding-top: 14px;
  }
  .vc-arrow{
    color: #161616;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    padding-bottom: 2px;
    transition: all .34s ease-out;
  }
  .vc-header{
    padding-top: 14px;
    margin-bottom: 8px;
  }
  .vc-nav-popover-container{
    background-color: #f7f7fa;
    border: 1px solid rgba(0, 0, 0, 0.06);
    color: #161616;
  }
  .vc-nav-item{
    font-weight: 400;
    text-transform: capitalize;

    &.is-current{
      color: #161616;
    }
    &.is-active{
      box-shadow: none;
      background-color: #5E4BF1!important;
      color: #fff!important;
    }
  }
  .vc-nav-arrow{
    transition: all .34s ease-out;
  }
  .vc-nav-header{
    margin-bottom: 4px;
  }
  .vc-nav-title{
    color: #161616;
    font-weight: 500;
    font-size: 16px;
    transition: all .34s ease-out;
  }
  .vc-nav-title:hover{
    background-color: rgba(0, 0, 0, 0.08);
  }
  .vc-nav-arrow:hover{
    background-color: rgba(0, 0, 0, 0.08);
  }
  .vc-nav-item:hover {
    background-color: rgba(0, 0, 0, 0.08);
    color: #161616;
    box-shadow: none;
  }
  .vc-arrow:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
  .vc-day-content:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
}

