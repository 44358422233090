











































































































































@import "../assets/scss/_variables.scss";

.search-block{
  background-color: #fff;
  padding: 24px;
  border-radius: 16px;
  &-input{
    margin-top: 12px;
    margin-bottom: 15px;
  }
}
.scroll-inner{
  overflow: auto;
  padding: 2px 10px 0 2px;
  width: 100%;
  min-height: 400px;
  box-sizing: content-box;
}
@media (max-width: 1200px){
  .scroll-inner{
    overflow: unset;
    paddingssssss: 0;
    margin-right: 0;
    height: auto!important;
  }
}
@media (max-width: 575px){
  .search-block{
    background-color: rgba(1,1,1,0);
    padding: 0;
    border-radius: 0;
    &-title{
      display: none;
    }
    &-input{
      margin-top: 0;
    }
  }
}
