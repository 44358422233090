/*==========  Select Style  ==========*/

div.select-wrapper-material {
	position: relative;
	> label{
		position: absolute;
		top: 14px;
		left: 12px;
		color: #767676;
		font-size: 14px;
		transition: 0.32s;
		z-index: 0;
		cursor: pointer;
	}
	button.btn-select{
		min-width: 150px;
		width: 100%;
		height: 48px;
		border-radius: 6px;
		background-color: rgba(0, 0, 0, 0.04);
		border: none;
		padding: 1px 12px 1px 12px;
		outline: none;
		transition: 0.24s;
	}
	.select{
		width: 100%;
		.checkboxLayer{
			top: calc(100% + 8px);
			border: none;
			border-radius: 16px;
			box-shadow: none;
			filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.12));
			padding: 16px;
			min-width: 100%;
		}
		.checkBoxContainer{
			padding-right: 10px;
			padding-top: 0;
			max-height: 190px;
			min-height: auto;
		}
		.selectItem{
			padding: 4px 12px;
			border-radius: 8px;
			font-size: 14px;
			font-weight: 400;
			color: #161616!important;
			.right{
				display: none;
			}
			.margin-left-20{
				margin-left: 0;
			}
		}
		.helperContainer{
			padding: 0 0 14px 0;
			display: none;
			.clearButton{
				display: none;
			}
			.inputFilter{
				font-size: 14px;
				font-weight: 400;
				color: #161616;
				height: 34px;
				background-color: rgba(0, 0, 0, 0.04);
				padding: 0 12px 0 12px;
				border-radius: 6px;
				outline: none;
				border: none;
				box-shadow: none;
				margin: 0;
				transition: 0.24s;
				&::placeholder{
					font-size: 14px;
					color: #767676;
				}
			}
		}
	}
	div.buttonLabel{
		width: 100%;
		> span:first-of-type{
			display: block;
			text-align: left;
			padding-top: 16px;
			color: #161616;
			opacity: 0;
			visibility: hidden;
			transition: 0.24s;
		}
		.caret{
			display: block;
			margin-left: auto;
			border-top: 6px solid #161616;
			border-right: 5px solid transparent;
			border-left: 5px solid transparent;
			position: absolute;
			top: 50%;
			right: 18px;
			transform: translateY(-50%);
		}
	}
	&.open-select{
		.btn-select{
			box-shadow: 0px 0px 0px 2px $color1;
		}
		> label{
			top: 5px;
			font-size: 12px;
			color: #5E4BF1;
			z-index: 2;
		}
		div.buttonLabel{
			> span:first-of-type{
				visibility: visible;
				opacity: 1;
			}
		}
	}
	&.selected{
		> label{
			top: 5px;
			font-size: 12px;
		}
		div.buttonLabel{
			> span:first-of-type{
				visibility: visible;
				opacity: 1;
			}
		}
	}
	&.top{
		.select .checkboxLayer {
			top: auto;
			bottom: calc(100% + 8px);
		}
	}
	&.search{
		.select{
			.helperContainer{
				display: block;
			}
		}
	}
}

/*==========  Hover  ==========*/

div.select-wrapper-material button.btn-select:hover{
	background-color: rgba(0, 0, 0, 0.08);
}
div.select-wrapper-material .select .helperContainer .inputFilter:hover{
	background-color: rgba(0, 0, 0, 0.08);
}


/*==========  MEDIA QUERIES  ==========*/

// @media (max-width: 1368px)

// @media (max-width: 1199px)

// @media (max-width: 991px)

@media (max-width: 768px){
	div.select-wrapper-material .select{
		.checkboxLayer{
			border-radius: 8px;
			padding: 12px;
		}
		.selectItem{
			padding: 2px 6px;
			min-height: 24px;
		}
	}
}

// @media (max-width: 575px)



