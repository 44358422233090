/*==========  Dadge Style  ==========*/

.badge{
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  padding: 4px 12px;
  color: #161616;
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
  &.sm{
    font-size: 16px;
    padding: 2px 6px;
    font-weight: 400;
    border-radius: 6px;
  }
  &-wrapper{
    margin-bottom: -8px;
    > .badge{
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
  &.checkbox-item{
    position: relative;
    overflow: hidden;
    display: inline-flex;
    cursor: pointer;
    transition: $transition;
    z-index: 2;
    input{
      display: none;
    }
    > span{
      transition: $transition;
    }
    > span:after{
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #ece9fd;
      z-index: -1;
      top: 0;
      left: 0;
      visibility: hidden;
      opacity: 0;
    }
    input:checked + span{
      color: #5E4BF1;
    }
    input:checked + span:after{
      visibility: visible;
      opacity: 1;
    }
  }
  &.btn{
    cursor: pointer;
    transition: $transition;
  }
}
.welcome-block{
  position: relative;
  background-image: url('../assets/img/bg-0234.jpg');
  background-size: cover;
  border-radius: 10px;
  padding: 18px 100px 18px 24px;
  color: #fff;
  font-weight: 600;
  .icon{
    display: block;
    width: 28px;
    line-height: 0;
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
    img{
      width: 100%;
    }
  }
}
.title-wrapper{
  padding-left: 56px;
  position: relative;
  margin-bottom: 20px;
  .icon{
    width: 40px;
    line-height: 0;
    position: absolute;
    left: 0;
    top: 0;
    img{
      width: 100%;
    }
  }
}
.wrapper-layer{
  padding: 24px 32px 90px 32px;
  background-color: #fff;
  border-radius: 16px;
  position: relative;
  background-image: url('../assets/img/decor-093-01.svg');
  background-repeat: no-repeat;
  background-position: right bottom;
  &:after{
    content: '';
    display: block;
    height: 36px;
    width: 9px;
    background-color: #F2F4F7;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    position: absolute;
    left: 0;
    top: 20px;
  }
  &.step-1{
    background-image: url('../assets/img/decor-093-02.svg');
  }
  &.step-2{
    background-image: url('../assets/img/decor-093-03.svg');
  }
}

/*==========  Hover  ==========*/

// @media (min-width: 1200px){}

/*==========  MEDIA QUERIES  ==========*/

@media (max-width: 1368px){}

// @media (max-width: 1199px){}

@media (max-width: 991px){
  .welcome-block {
    padding: 10px 100px 10px 24px;
    font-weight: 500!important;
  }
}

// @media (max-width: 768px){}

 @media (max-width: 575px){
   .title-wrapper {
     margin-bottom: 15px;
     padding-left: 42px;
     .icon{
       width: 30px;
     }
   }
   .welcome-block {
     padding: 6px 60px 6px 12px;
     .icon {
       right: 18px;
       width: 20px;
     }
   }
   .wrapper-layer {
     padding: 20px 34px 80px 34px;
     //background-image: none!important;
     background-size: 220px;
     &.step-0{
       background-size: 160px;
     }
     &:after {
       top: 14px;
     }
   }
 }




