/*==========  labBanner Style  ==========*/

.banner-rg{
  min-height: calc(100vh - 48px);
  border-radius: 16px;
  background-color: #FFFFFF;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  top: 24px;
  z-index: 6;
  &-image{
    line-height: 0;
    img{
      max-width: 100%;
    }
  }
  &-logo{
    width: 100px;
    line-height: 0;
    position: absolute;
    top: 44px;
    right: 44px;
    img{
      width: 100%;
    }
  }
}
.agile{
  .agile__actions{
    margin-top: 20px;
    justify-content: flex-start;
  }
  &__dot{
    margin: 0 4px;
    &:first-of-type{
      margin-left: 0;
    }
    button{
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $colorGrey6-2;
      transition: $transition;
    }
    &--current{
      button{
        background-color: $colorGrey3;
      }
    }
  }
}

/*==========  Hover  ==========*/

@media (min-width: 991px){
  .agile__dot button:hover{
    background-color: $colorGrey3;
  }
}

/*==========  MEDIA QUERIES  ==========*/

@media (max-width: 1368px){
  .banner-rg{
    padding: 24px;
  }
}

@media (max-width: 1199px){
  .agile .agile__actions{
    margin-top: 14px;
  }
}

@media (max-width: 991px){
  .banner-rg{
    display: none;
  }
}

// @media (max-width: 768px){}

// @media (max-width: 575px){}




