/*-------------------------------------------------------------------------------------------------------------------------------*/
/* GLOBAL SETTINGS */
/*-------------------------------------------------------------------------------------------------------------------------------*/

@import "variables";
@import "reset";
@import "common";
@import "typography";
@import "buttons";
@import "form";
@import "select";
@import "modification";

html {
	height: auto;
}

body {
	height: auto;
	overflow: visible;
}

body ::selection {
	color: #fff;
	background: #000;
}

body ::-moz-selection {
	color: #fff;
	background: #000;
}

#content-block{
	padding-top: 24px;
	padding-bottom: 24px;
	min-height: 100vh;
	background-color: #F2F4F7;
	&.sp-left{
		padding-left: 64px;
	}
}
@media (max-width: 991px){
	#content-block{
		padding-top: 25px;
	}
}



