










































































































.stepper-container{
  position: relative;
}
@media (max-width: 575px) {
  .stepper-container{
    padding-bottom: 60px;
    /*
    &-skip{
      position: fixed !important;
      bottom: 58px !important;
      right: 16px !important;
      left: 16px !important;
      width: calc(100% - 32px);
    }
    */
  }
}
