/*==========  Stepper Style  ==========*/

.stepper-container{
  .stepper-box{
    min-height: auto;
    background-color: rgba(1,1,1,0);
    box-shadow: none;
    .content{
      margin-bottom: 30px;
      overflow: unset;
    }
    .top{
      .divider-line{
        top: 6px;
        left: 0;
        height: 1px;
        background-color: #98A2B3;
        border-color: #98A2B3;
        opacity: 0.2;
      }
      .steps-wrapper{
        counter-reset: number;
        padding: 0;
        width: 100%;
        .step{
          line-height: 0;
          text-align: left;
          align-items: flex-start;
          &-title{
            &:before {
              font-size: 14px;
              line-height: 1.4em;
              counter-increment: number;
              content: "Step " counter(number) ;
            }
          }
          .circle{
            margin-bottom: 10px;
            background-color: inherit;
            padding: 0;
            position: relative;
            &:before,
            &:after{
              content: '';
              display: block;
              position: absolute;
              width: 8px;
              height: 100%;
              top: 0;
              background-color: #F2F4F7;
            }
            &:after{
              left: 100%;
            }
            &:before{
              right: 100%;
            }
            i{
              background-color: #5E4BF1;
              padding: 0;
              width: 12px;
              height: 12px;
            }
          }
          .step-title h4{
            font-size: 14px;
            font-weight: 400;
            color: #98A2B3;
          }
          .step-title {
            max-width: 180px;
          }
          &.deactivated{
            .circle i{
              background-color: #5E4BF1!important;
            }
            .step-title{
              opacity: 1;
            }
          }
        }
        .activated.step ~ .step.deactivated{
          .circle i{
            background-color: #98A2B3!important;
          }
          .step-title {
            h4{
              color: #98A2B3;
            }
            &:before {
              color: #98A2B3!important;
            }
          }
          //background-color: red;
        }
      }
    }
    .bottom{
      border: none;
      padding: 0;
      .stepper-button{
        &.next{
          background-color: #5E4BF1;
          border-radius: 6px;
          padding: 6px 12px;
          font-size: 14px;
          box-shadow: none;
          transition: all .34s ease-out;
          &.deactivated{
            background-color: #F0F0F0 !important;
            color: #AAAAAA;
            cursor: pointer!important;
          }
          i{
            display: none!important;
          }
        }
        &.previous{
          display: none;
        }
      }

      &.only-next{
        justify-content: flex-start;
      }
    }
  }
}
.stepper-mob-title{
  display: none;
}
/*==========  Hover  ==========*/

@media (min-width: 1200px){
  .stepper-container .stepper-box .bottom .stepper-button.next:hover{
    background-color: #7161F3;
  }
}

/*==========  MEDIA QUERIES  ==========*/

// @media (max-width: 1368px){}

@media (max-width: 1199px){
  .stepper-container .stepper-box .content{
    margin-bottom: 20px;
  }
}

@media (max-width: 991px){
  .stepper-container .stepper-box{
    .top{
      /* display: none; */
      margin-bottom: 44px;
    }
    .content{
      margin-top: 0;
    }
  }
  .stepper-mob-title{
    display: none;
  }
  .stepper-container {
    .stepper-box .top {
      .divider-line{
        display: none;
      }
      .steps-wrapper {
        .step{
          display: block!important;
          flex: 0 0 25%;
          transition: all .34s ease-out;
          .circle{
            &:after{
              display: none;
            }
            &:before{
              content: '';
              height: 1px;
              width: calc(100% - 60px);
              left: 36px;
              background-color: #98A2B3;
              opacity: 0.2;
              top: 50%;
            }
          }
          .step-title{
            visibility: hidden;
            opacity: 0;
          }
          &.activated{
            flex: 0 0 50%;
            .step-title{
              visibility: visible;
              opacity: 1;
            }
          }
          &:last-child{
            .circle{
              &:before{
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

// @media (max-width: 768px){}

@media (max-width: 575px){
  .stepper-container .stepper-box .bottom .stepper-button.next{
    position: fixed!important;
    bottom: 16px!important;
    right: 16px!important;
    left: 16px!important;
    text-align: center;
    justify-content: center;
  }
  .stepper-container{
    padding-bottom: 15px!important;
  }
  .stepper-container .stepper-box .top {
    margin-bottom: 10px;
  }
}




