




































































































































































































.select-wrapper-material{
  &.visible-only-first{
    .selectItem{
      pointer-events: none;
      opacity: 0.4;
      &:first-child{
        pointer-events: auto;
        opacity: 1;
      }
    }
  }
}
