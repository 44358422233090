




















































































































































































































































































































































.mb-50{
  margin-bottom: 50px;
}
.mb-20{
  margin-bottom: 20px;
}
.mb-10{
  margin-bottom: 20px;
}

.mt-10{
  margin-top: 10px;
}

.hr-s{
  border-bottom: 1px solid #D6DDEA;
  display: block;
  width: 100%;
  margin: 10px 0;
}

