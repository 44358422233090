/*==========  Header Style  ==========*/

.header{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 36px 0 12px 0;
  background: #F2F4F7;
  z-index: 2;
  &-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-logo{
    display: inline-block;
    width: 160px;
    line-height: 0;
    img{
      max-width: 100%;
    }
  }
  &-rg{
    margin-left: 32px;
  }
}

/*==========  Hover  ==========*/

// @media (min-width: 1200px){}

/*==========  MEDIA QUERIES  ==========*/

@media (max-width: 1368px){
  .header{
    &-logo{
      width: 180px;
    }
  }
}

// @media (max-width: 1199px){}

@media (max-width: 991px){
  .header{
    padding: 24px 0 12px 0;
    &-logo{
      width: 100px;
    }
  }
}

// @media (max-width: 768px){}

// @media (max-width: 575px){}




