/*========== Style  ==========*/

.client-modification{
  .banner-rg{
    background-color: #000000;
    border-radius: 25px;
    justify-content: center;
    padding: 200px 44px 100px 44px;
    &-text{
      padding-left: 240px;
    }
    .agile__dot button{
      background-color: rgba(255, 255, 255, 0.12);
    }
    .agile__dot--current button{
      background-color: #fff;
    }
    .agile{
      position: static;
    }
    .agile__actions {
      position: absolute;
      left: 44px;
      bottom: 74px;
    }
  }
  .stepper-container-buttons{
    position: absolute;
    left: 32px;
    bottom: 32px;
    .btn{
      margin-right: 12px;
    }
  }
  .stepper-container{
    .stepper-box{
      .content{
        margin-bottom: 0;
      }
      .bottom .stepper-button.next{
        display: none;
      }
    }
  }
}


/*==========  Hover  ==========*/

@media (min-width: 991px){}

/*==========  MEDIA QUERIES  ==========*/

//@media (max-width: 1368px){}

//@media (max-width: 1199px){}

//@media (max-width: 991px){}

// @media (max-width: 768px){}

@media (max-width: 575px) {
  /*
  .client-modification .stepper-container-skip {
    background-color: #F2F4F7;
    left: 15px;
  }
  .client-modification .stepper-container-next{
    position: fixed;
    bottom: 16px;
    right: 16px;
    left: 16px;
    text-align: center;
    justify-content: center;
    margin-right: 0!important;
  }
  */
  .client-modification {
    .stepper-container-buttons{
      left: 34px;
      bottom: 34px;
    }
  }
}




